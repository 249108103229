import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home/";
import Properties from "../views/Properties/";
import Property from "../views/Properties/Detail";
import Developments from "../views/Developments/";
import Development from '../views/Developments/Detail/';
import DevelopmentSections from '../views/Developments/Detail/sections.vue';
import Privacy from '../views/Privacy/'
import Agents from "../views/Agents/";
import Agent from '../views/Agents/Detail/';
import NotFound from "../views/Error/";
import Contact from "../views/Contact/";
import About from "../views/About/";
import Results from '../views/Results/';
import Pruebas from "../views/Pruebas/";

Vue.use(VueRouter)

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            hideBanner: true
        }

    },
    {
        path: "/propiedades",
        name: "Properties",
        component: Properties,
        meta: {
            pageTitle: "routes.properties.page_title",
        },
        children: [{
            //Use when share a list of properties from IMMO CRM 360
            path: "/propiedades/:codeShareProperties",
            name: "ShareProperies",
            component: Properties,
            meta: {
                pageTitle: "routes.properties.page_title",
            },
        }],
    },
    {
        path: "/propiedad/:folio/:namePropFilt",
        name: "Property",
        component: Property,
        meta: {
            hideBanner: true
            
        },
        children: [{
                // Use when agentId exist
                path: "/propiedad-:folio-:agentId",
                name: "PropertyAgent",
                component: Property,
                meta: {
                    hideBanner: true,
                },
            },
            {
                // Use when namePropFilt not exist
                path: "/propiedad/:folio",
                name: "PropertyShort",
                component: Property,
                meta: {
                    hideBanner: true,
                },
            },
        ],
    },
    {
        path: '/desarrollos',
        name: 'Developments',
        component: Developments,
        meta: {
            pageTitle: 'routes.developments.page_title',
        }
    },
    {
        path: '/desarrollo/:folio/:nameDevFilt',
        name: 'Development',
        component: Development,
        meta: {
            hideBanner: true
        },
        children: [{
            // Use when namePropFilt not exist
            path: "/desarrollo/:folio",
            name: "DevelopmentOutName",
            component: Development,
            meta: {
                hideBanner: true,
            },
        }, ],
    },
    {
      path: "/desarrollo-secciones/:folio",
      name: "DevelopmentSections",
      component: DevelopmentSections,
      meta: {
          hideBanner: true,
          hideSearch: true,
      },
  },
    {
        path: "/contacto",
        name: "Contact",
        component: Contact,
        meta: {
            pageTitle: 'routes.contact.page_title',
        }
    },
    {
        path: "/nosotros",
        name: "About",
        component: About,
        meta: {
            pageTitle: 'routes.about.page_title',
        }
    },{
      path: "/aviso-de-privacidad",
      name: "Privacy",
      component: Privacy,
      meta: {
          pageTitle: 'routes.about.page_title',
      }
  },
    {
        path: '/busqueda',
        name: 'Results',
        component: Results,
        meta: {
            pageTitle: 'routes.search_results.page_title',
        },
        children: [{
            path: '/busqueda-desarrollos',
            name: 'ResultsDevs',
            component: Results,
            meta: {
                pageTitle: 'routes.search_results.page_title_developments',
            }
        }]
    },
    {
        path: "/agentes",
        name: "Agents",
        component: Agents,
        meta: {
            pageTitle: 'routes.agents.page_title',
        }
    },
    {
        path: '/agente/:folio/:nameAgentFilt',
        name: 'Agent',
        component: Agent,
        meta: {
            hideBanner: true,
        }
    },
    {
        path: '/prueba',
        name: 'Pruebas',
        component: Pruebas,
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
        name: "Error",
        meta: {
            pageTitle: 'routes.error.page_title',
        }
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router