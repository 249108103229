<template>
  <div>
    <Preloader v-if="!prop" />

    <section v-else class="blog_post section-padding">
      <Banner
        class="mb-3"
        :pageTitle="
          activeLocale == 'es' || !prop.propertyNameEn
            ? prop.propertyNameEs
            : prop.propertyNameEn
        "
      />
      <div class="container">
        <div class="row">
          <!-- SECTION LEFT -->
          <div class="col-md-8 col-sm-12 col-xs-12">
            <!-- CAROUSEL -->
            <DetailCarousel :items="prop.images" />

            <div class="blog_post_left">
              <!-- FOLIO % ADDRESS -->
              <div class="row">
                <div class="col-2 single_blog_title">
                  <h4 v-if="prop.folio != '0'">
                    <span> # </span> {{ prop.folio }}
                  </h4>
                </div>
                <div class="col-10 single_blog_title">
                  <h5 v-if="prop.address != '0'">{{ prop.address }}</h5>
                  <h5 v-if="prop.referenceEs != '0'">
                    {{ prop.referenceEs }}
                  </h5>
                </div>
              </div>
              <br />
              <div class="text-center">
                <!-- BEST INFORMATION -->
                <ul class="features row justify-content-center">
                  <li class="col-lg-4" v-if="prop.parkingTot != 0">
                    <span>
                      {{ $t("pages.property_detail.feature_parking") }}
                      <br />
                      {{ $t("pages.property_detail.amenities_interior") }}
                    </span>
                    <br />
                    <span>
                      <b>{{ prop.parkingTot }}</b>
                    </span>
                  </li>

                  <li class="col-lg-4" v-if="prop.bedrooms != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.feature_bedrooms") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b>{{ prop.bedrooms }}</b>
                    </span>
                  </li>

                  <li class="col-lg-4" v-if="prop.bathrooms != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.feature_bathrooms") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b>{{ prop.bathrooms }}</b>
                    </span>
                  </li>

                  <li class="col-lg-4" v-if="prop.middlebathrooms != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.feature_bathrooms") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b>1/2 {{ prop.middlebathrooms }}</b>
                    </span>
                  </li>

                  <li class="col-lg-4" v-if="prop.m2c != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.feature_area_cons") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b> {{ prop.m2c }} m<sup>2</sup> </b>
                    </span>
                  </li>

                  <li class="col-lg-4" v-if="prop.mlot != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.feature_area_lot") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b> {{ prop.mlot }} m<sup>2</sup> </b>
                    </span>
                  </li>
                  <li class="col-lg-4" v-if="prop.nameTower != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.nameTower") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b>{{ prop.nameTower }}</b>
                    </span>
                  </li>
                  <li class="col-lg-4" v-if="prop.numberTower != 0">
                    <span class="property-specs">
                      {{ $t("pages.property_detail.numberTower") }}
                    </span>
                    <br />
                    <span class="property-specs-number">
                      <b>{{ prop.numberTower}}</b>
                    </span>
                  </li>

                </ul>
              </div>
            </div>

            <!-- PRICES -->
            <div class="blog_post_left text-center">
              <div>
                <div
                  class="row justify-content-center"
                  v-if="
                    prop.operation.sold != 0 ||
                      prop.operation.opportunity_sold != 0
                  "
                >
                  <div v-if="prop.operation.sold != 0" class="col-md-6">
                    <h2 class="h2-price">
                      {{ prop.operation.sold }}
                    </h2>
                    <strong
                      :class="[
                        prop.operation.opportunity_sold != 0 ? 'not-price' : '',
                        'h1 mb-3',
                      ]"
                    >
                      {{ prop.prices.sold }}
                    </strong>
                  </div>
                  <div v-if="prop.operation.rent != 0" class="col-md-6">
                    <h2 class="h2-price">
                      {{ prop.operation.rent }}
                    </h2>
                    <strong
                      :class="[
                        prop.operation.opportunity_rent != 0 ? 'not-price' : '',
                        'h1 mb-3',
                      ]"
                    >
                      {{ prop.prices.rent }}
                    </strong>
                  </div>
                </div>
                <div class="row">
                  <div
                    v-if="prop.operation.opportunity_sold != 0"
                    class="col-md-6 mt-3"
                  >
                    <h2 class="h2-price">
                      {{ prop.operation.opportunity_sold }}
                    </h2>
                    <strong>
                      {{ prop.prices.opportunity_sold }}
                    </strong>
                  </div>
                  <div
                    v-if="prop.operation.opportunity_rent != 0"
                    class="col-md-6 mt-3"
                  >
                    <h2 class="h2-price">
                      {{ prop.operation.opportunity_rent }}
                    </h2>
                    <strong>
                      {{ prop.prices.opportunity_rent }}
                    </strong>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <!-- DESCRIPTION -->
            <div class="blog_post_left">
              <div class="section-title text-center wow zoomIn">
                <h2>{{ $t("pages.property_detail.description_prop") }}</h2>
                <div></div>
              </div>
              <div v-if="activeLocale == 'es' || !prop.descriptionEn">
                <p>
                  <pre>{{ prop.descriptionEs }}</pre>
                </p>
              </div>
              <div v-else>
                <p >
                  <pre>{{ prop.descriptionEn }}</pre>
                </p>
              </div>
            </div>

            <!-- AMENITIES -->
            <div class="blog_post_left">
              <div
                v-if="
                  prop.interiorAmenities ||
                    prop.otherAmenities ||
                    prop.exteriorAmenities ||
                    prop.services ||
                    prop.developmentServices
                "
                class="section-title text-center wow zoomIn"
              >
                <h2>{{ $t("pages.property_detail.amenities_prop") }}</h2>
                <div></div>
              </div>
              <div
                v-if="
                  prop.interiorAmenities ||
                    prop.otherAmenities ||
                    prop.exteriorAmenities ||
                    prop.services ||
                    prop.developmentServices
                "
              >
                <div
                  class="container"
                  v-if="prop.interiorAmenities || prop.otherAmenities"
                >
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_interior") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.hall || prop.interiorAmenities.sala">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_sala") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.kitchen  || prop.interiorAmenities.cocina">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_cocina") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.dinning_room || prop.interiorAmenities.comedor">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_comedor") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.service_area || prop.interiorAmenities.area_servicio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_areadeservicio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.amueblado">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_amueblado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cuartoTv">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_cuartoTV") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.estudio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_estudio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cocinaIntegral">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_cocinaIntegral") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoLavado">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_ctoLavado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoServBaño">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_ctoServBano") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.area_servicio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_ctoServBano") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.aa">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_aa") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.minisplit">
                        <i class="fas fa-check"></i>
                        Mini split
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ventiladores">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_ventiladores") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.calentadorAgua">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_calentadorAgua") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.casaInteligente">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_casaInteligente") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.microondas">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_microondas") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.lavaPlatos">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_lavaPlatos") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.camaraFria">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.int_camaraFria") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.central">
                        <i class="fas fa-check"></i>
                        Central
                    </p>
                  </div>
                </div>

                <div class="container" v-if="prop.exteriorAmenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_exterior") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.balcon">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_balcon") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.terraza">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_terraza") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jardin">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_jardin") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.alberca">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_alberca") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.elevador">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_elevador") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.estacionamiento">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_estacionamiento") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bbq">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_bbq") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bar">
                        <i class="fas fa-check"></i>
                        Bar
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.gimnasio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_gimnasio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.muelle">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_muelle") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cisterna">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_cisterna") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jacuzzi">
                        <i class="fas fa-check"></i>
                        Jacuzzi
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.plantaElectrica">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_plantaElectrica") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cercoElectrico">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_cercoElectrico") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.puertaMuLock">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_puertaMuLock") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.almacen">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_almacen") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.panelesSolares">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_panelesSolares") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaManiobras">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_areaManiobras") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.casetaSeguridad">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_casetaSeguridad") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasMetalicas">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_cortinasMetalicas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaDescarga">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_areaDescarga") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasAnticiclonicas">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_cortinasAnticiclonicas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.techado">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_techado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.solarium">
                        <i class="fas fa-check"></i>
                        Solarium
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.patio_servicio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.ext_patioservicio") }}
                    </p>
                  </div>
                </div>

                <div class="container" v-if="prop.developmentAmenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.amenities_maintenance") }}
                    </h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.condominio">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_condominio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.cafetera">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_cafetera") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubGolf">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_clubGolf") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.casaClub">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_casaClub") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.alberca_d">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_alberca_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.muelle_d">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_muelle_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubPlaya">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_clubPlaya") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.spa">
                        <i class="fas fa-check"></i>
                        SPA
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.gimnasio_d">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_gimnasio_d") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.canchaTenis">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_canchaTenis") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.juegosInf">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_juegosInf") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.lobby">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_lobby") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.elevador_d">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_elevador_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.salonEventos">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_salonEventos") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.restaurante">
                        <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_restaurante") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.comercios">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_comercios") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.bodega">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_bodega") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.seguridad">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_seguridad") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.accesoControlado">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_accesoControlado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servicioAdmin">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_servicioAdmin") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.centroNegocios">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_centroNegocios") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.albercaAlquiler">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_albercaAlquiler") }} 
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servConcierge">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_servConcierge") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.otherServices">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_otherServices") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2constructionDev">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_m2constructionDev") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2greenArea">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_m2greenArea") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.palapa">
                        <i class="fas fa-check"></i>
                        Palapa
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.marina">
                        <i class="fas fa-check"></i>
                        {{ $t("pages.amenities.man_marina") }} 
                    </p>
                  </div>
                </div>

                <div
                  class="container"
                  v-if="prop.developmentServices || prop.services"
                >
                  <div class="row mb-3">
                    <h4 class="h4 text-black">
                      {{ $t("pages.property_detail.services_prop") }}
                    </h4>
                  </div>
                  <div class="row">
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.mantenimiento">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_mantenimiento") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.servTransp">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servTransp") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.tour">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_tour") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.tiendaComestibles">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_tiendaComestibles") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.servLimpieza">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servLimpieza") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.servAgua">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servAgua") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.servLocalCel">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servLocalCel") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.rampasSillasRuedas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_rampasSillasRuedas") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.luz">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_luz") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.agua">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_agua") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.gas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_gas") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.circuitoCerrado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_circuitoCerrado") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.energiaSustentable">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_energiaSustentable") }}
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.alumbrado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_alumbrado") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.cableTV">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_cableTV") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.wifi">
                      <i class="fas fa-check"></i>
                      Wi-Fi
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.concierge">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_concierge") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.mascotas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_mascotas") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.fumar">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_fumar") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.sistemaDeAlarma">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_sistemaDeAlarma") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.servTelefono">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servTelefono") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.camarasDeSeguridad">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_camarasDeSeguridad") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.drenaje">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_drenaje") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.pavimento">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_pavimento") }} 
                  </p>
                  <p class="amenidades col-md-4" v-if="prop.developmentServices.poolRentas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_poolRentas") }} 
                  </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- SECTION RIGHT -->
          <div class="col-md-4 col-sm-12 col-xs-12 p-5">
            <!-- CONTACT -->
            <div class="categories wow fadeInRight">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.contact_us") }}
              </h4>
              <div class="text" style="text-align: center">
                <p class="mb-0">
                  <span> {{ $t("pages.property_detail.agent_phone") }} : </span>
                  <a
                    class="contact"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Contactar en WhatsApp"
                    target="_blank"
                    :href="
                      `https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_agent'
                      )}: ${url}&phone=52${prop.agent.cellphone}`
                    "
                  >
                    {{ prop.agent.cellphone }}
                  </a>
                </p>
                <p class="mb-4 ">
                  <span>{{ $t("pages.property_detail.agent_email") }} :</span>
                  <a class="contact"> {{ prop.agent.email }}</a>
                </p>
              </div>
              <ContactForm />
            </div>

            <!-- DOWNLOAD -->
            <div class="video_post wow fadeInRight mt-3">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.share_title") }}
              </h4>
              <div>
                <h4 class=" text-black">Banners</h4>
                <div class="tag">
                  <a target="_blank" :href="prop.banner1">
                    <i class="fa fa-file-image-o"></i> 1 Foto</a
                  >
                  <a target="_blank" :href="prop.banner3">
                    <i class="fa fa-file-image-o"></i> 3 Fotos</a
                  >
                  <a target="_blank" :href="prop.banner4"
                    ><i class="fa fa-file-image-o"></i> 4 Fotos</a
                  >
                  <a target="_blank" :href="prop.banner6"
                    ><i class="fa fa-file-image-o"></i> 6 Fotos</a
                  >
                </div>
              </div>
              <br />
              <div>
                <h4 class=" text-black">Flyers</h4>
                <div class="tag justify-content-center">
                  <a target="_blank" :href="prop.flyerEs">
                    <i class="fa fa-file-image-o"></i> FLYER ESPAÑOL</a
                  >
                  <a target="_blank" :href="prop.flyerEn">
                    <i class="fa fa-file-image-o"></i> ENGLISH FLYER</a
                  >
                </div>
              </div>
            </div>

            <!-- SHARE -->
            <div class="categories wow fadeInRight mb-5">
              <h4 class="blog_sidebar_title">
                {{ $t("pages.property_detail.share_prop") }}
              </h4>
              <ul class="social-networks">
                <li>
                  <a
                    :href="
                      `https://www.facebook.com/sharer/sharer.php?u=${url}`
                    "
                    target="_blank"
                    ><i class="fab fa-facebook" aria-hidden="true"></i
                  ></a>
                </li>
                <li>
                  <a
                    title="Compartir por Twitter"
                    :href="`https://twitter.com/home?status=${url}`"
                    target="_blank"
                  >
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    title="Compartir por WhatsApp"
                    :href="
                      `https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_public'
                      )}: ${url}`
                    "
                    target="_blank"
                    ><i class="fab fa-whatsapp"></i
                  ></a>
                </li>
              </ul>
            </div>
            <br />

            <!-- LOCATION -->
            <div class="mb-5 mt-5" v-if="prop.latitude || prop.longitude">
              <div class="col-md-12 no-gutters mb-5">
                <div class="col-12">
                  <h4 class="blog_sidebar_title">
                    {{ $t("pages.property_detail.map_title") }}
                  </h4>
                </div>
                <div class="col-12 ">
                  <GoogleMap :mapLat="prop.latitude" :mapLng="prop.longitude" />
                </div>
              </div>
            </div>

            <!-- VIDEO -->
            <div class="mb-5 mt-5" v-if="prop.video">
                <div class="col-md-12 no-gutters mt-3">
                  <div class="col-12">
                    <h2 class="text-center mb-5">
                      {{ $t("pages.property_detail.video") }}
                    </h2>
                  </div>
                  <div class="col-12">
                    <iframe
                      width="100%"
                      height="315"
                      :src="prop.video"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
              </div>
            </div>
          </div>
          <!--- END COL -->
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
export default {
  components: {
    Banner,
    Preloader,
    SearchForm,
    ContactForm,
    DetailCarousel,
    GoogleMap,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.prop.bestprice[0];
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };
        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
    this.sliderStyle();
  },
  methods: {
    sliderStyle() {
      setTimeout(function() {
        /*----------------------------------------------------*/
        /*  owlCarousel
					/*----------------------------------------------------*/
        if ($(".nonloop-block-13").length > 0) {
          $(".nonloop-block-13").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            autoplay: true,
            margin: 20,
            nav: false,
            dots: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                margin: 20,
                stagePadding: 0,
                items: 1,
              },
              1000: {
                margin: 20,
                stagePadding: 0,
                items: 2,
              },
              1200: {
                margin: 20,
                stagePadding: 0,
                items: 3,
              },
            },
          });
        }
        if ($(".slide-one-item").length > 0) {
          $(".slide-one-item").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            margin: 0,
            autoplay: true,
            pauseOnHover: false,
            nav: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
          });
        }
        if ($(".nonloop-block-4").length > 0) {
          $(".nonloop-block-4").owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            nav: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                items: 1,
              },
            },
          });
        }
      }, 400);
      return;
    },
  },
};
</script>

<style scoped>
/* COLUMN 8 */
/* GENERAL */

.single_blog_title h4 span {
  color: #138cca !important;
  margin: 0;
}

.features {
  font-family: "Montserrat", sans-serif;
  color: #2a2a2a;
  list-style: none;
  font-size: 13px;
}
.not-price {
  color: #616161 !important;
  text-decoration: line-through;
  font-size: 15px;
}

strong {
  font-size: 20px;
}

pre {
  white-space: pre-wrap;
  height: auto;
  overflow: hidden;
  background: transparent;
  border-color: transparent;
  color: #000;
  font-family: "Montserrat",sans-serif, Open Sans, sans-serif;
  font-size: 15px;
  text-align: justify;
}

.des-title {
  padding-left: 15px;
  text-align: justify;
}

/* amenities */
.amenidades {
  font-family: "Montserrat", Open Sans, sans-serif;
  font-size: 12px;
}
.amenidades i {
  color: #138cca;
}

.h4 {
  text-align: left;
  padding-left: 50px;
  font-size: 20px;
}

.contact {
  color: #373737 !important;
}

.contact:hover {
  color: #138cca !important;
}

/* banners flyers */

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.buttons-content {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
}
.button-flyer {
  display: block;
  color: #373737;
  width: auto;
  border: 2px solid #138cca;
  border-radius: 4px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.button-flyer:hover {
  background: #138cca !important;
  color: rgb(255, 255, 255);
  outline: none !important;
}

.tag {
  display: block;
  margin-top: 10px !important;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  margin-bottom: 50px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.social-networks li {
  float: left !important;
  text-align: center !important;
  width: 3em;
  font-size: 25px;
  display: inline;
}

.categories ul {
  text-align: center !important;
  margin-left: 80px;
}

/* PRICES */
.h2-price {
  color: #138cca !important;
}

.text{
  font-size: 13px;
}
</style>
