<template>
  <div class="col-md-4 col-sm-12 col-xs-12 mb-5">
    <div
      class="single_property"
      @click="goToProperty(item.folio, item.namePropertyEs)"
    >
      <div
        v-if="info.logoClean"
        class="watermark"
        :style="'background-image:url(' + info.logoClean + '); '"
      ></div>
      <img
        :src="item.smallphoto"
        id="propImg"
        :alt="item.alt"
        :title="item.namePropertyEs + '-IMAGE'"
        class="img-responsive"
      />

      <div class="single_property_description text-center">
        <ul class="single_property_list text-center ">
          <li>
            <span v-if="item.bedrooms != '0'"
              ><i class="fa fa-bed"></i> {{ item.bedrooms }}
              {{ $t("components.property_card.bedrooms") }}</span
            >
          </li>
          <li>
            <span v-if="item.bathrooms"
              ><i class="fas fa-bath"></i> {{ item.bathrooms }}
              {{ $t("components.property_card.bathrooms") }}</span
            >
          </li>
          <li>
            <span v-if="item.m2c != 0">
              <i class="fas fa-expand-alt"></i> {{ item.m2c }} m
                <sup>2</sup>c
            </span>
          </li>
          <li>
            <span v-if="item.mlot != 0">
              <i class="fas fa-expand-alt"></i>{{ item.mlot }} m<sup>2</sup>c
            </span>
          </li>
        </ul>
      </div>
      <div class="single_property_content">
        <h4 v-if="item.namePropertyEs">
          <a @click="goToProperty(item.folio, item.namePropertyEs)">{{
            item.namePropertyEs
          }}</a>
        </h4>
        <p v-if="item.address">{{ item.address }}</p>
      </div>
      <div v-if="item.bestPrice" class="single_property_price">
        <span>{{ item.bestPrice }}</span>
      </div>
    </div>
    <!--- END SINGLE PROPERTY -->
  </div>
  <!--- END COL -->
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainPrice: 0,
      secondPrice: 0,
      mainOperation: 0,
      secondOperation: 0,
    };
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    priceInfo() {
      //Formateo de decimales
      let decimalPrice = this.mainPrice.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = this.mainPrice.indexOf("MXN");
      let currentPriceU = this.mainPrice.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = this.mainPrice.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += this.mainPrice.substr(currentPriceM);
      } else {
        formatPrice += this.mainPrice.substr(currentPriceU);
      }
      return formatPrice;
    },
  },

  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Property",
        params: {
          folio,
          namePropFilt,
        },
      });
    },
  },
  created() {
    /* For Base Template*/
    let prices = this.item.prices;
    let operations = this.item.operations;
    if (prices.opportunity_sold) {
      this.mainPrice = prices.opportunity_sold;
      this.mainOperation = operations.opportunity_sold;
      this.secondPrice = prices.sold;
      this.secondOperation = operations.sold;
    } else if (prices.opportunity_rent) {
      this.mainPrice = prices.opportunity_rent;
      this.mainOperation = operations.opportunity_rent;
      this.secondPrice = prices.rent;
      this.secondOperation = operations.rent;
    } else if (prices.sold) {
      this.mainPrice = prices.sold;
      this.mainOperation = operations.sold;
    } else if (prices.rent) {
      this.mainPrice = prices.rent;
      this.mainOperation = operations.rent;
    }
  },
};
</script>

<style scoped>
.item {
  width: fit-content;
}

.watermark {
  height: 260px !important;
  width: 100%;
  background-size: 25%;
  z-index: 1;
  cursor: pointer;
}
.watermark {
  opacity: 0.6;
}

.single_property_price span {
  font-family: "Merriweather", sans-serif;
  color: #373737;
}

.single_property_list {
  list-style: none;
  /* columns: 2; */
  display: flex;
  align-items: center;
}

#propImg {
    width: 100%;
    height: 240px !important;
}
</style>
