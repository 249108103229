<template>
  <section class="template_property">
    <div class="container">
      <NotResults v-if="error == 204" code="204" />
      <div v-else class="row justify-content-center">
          <Preloader v-if="!developments" />
          <DevelopmentCard
            v-else
            v-for="(item, i) in developments"
            :key="item.folio"
            :name="item.folio"
            :index="i"
            :item="item"
          />
        </div>
      </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import Preloader from "@/components/layout/Preloader.vue";
import DevelopmentCard from "@/components/developments/DevelopmentCard.vue";
import NotResults from '@/components/layout/NotFound';
import SearchForm from "@/components/search/SearchForm.vue";
export default {
  components: {
    DevelopmentCard,
    Preloader,
    NotResults,
    SearchForm
  },
  computed: {
    ...mapGetters({
      developments: "_getDevelopments",
      error: "_getError",
    }),
  },
  created() {
    this.$store.dispatch("getDevelopments");
  },
};
</script>
