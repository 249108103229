<template>
  <div class="col-md-4 col-sm-12 col-xs-12">
    <div
      class="single_property"
      @click="goToDevelopment(item.folio, item.developmentNameEs)"
    >
      <div
        v-if="info.logoClean"
        class="watermark"
        :style="'background-image:url(' + info.logoClean + '); '"
      ></div>
      <img
        :src="item.image.smallphoto"
        id="propImg"
        :alt="item.alt"
        :title="item.developmentNameEs + '-IMAGE'"
        class="img-responsive"
      />

      <div class="single_property_description text-center">
        <span v-if="item.folio != '0'"
          ><i class="fas fa-hashtag"></i> {{ item.folio }}</span
        >
      </div>
      <div class="single_property_content">
        <h4 v-if="item.developmentNameEs">
          <a @click="goToDevelopment(item.folio, item.developmentNameEs)">{{
            item.developmentNameEs
          }}</a>
        </h4>
        <p>
          <span v-if="item.colony != '0'">{{ item.colony }} </span>
          <span v-if="item.colony != '0' && item.city != '0'">,</span>
          <span v-if="item.city != '0'">{{ item.city }}</span>
        </p>
        <p>{{ $t("components.property_card.legend") }}</p>
      </div>
      <div v-if="item.priceFrom" class="single_property_price">
        <span>{{ item.priceFrom }}</span>
      </div>
    </div>
    <!--- END SINGLE PROPERTY -->
  </div>
  <!--- END COL -->
</template>

<script>
import { mapGetters } from "vuex";
import services from "@/store/_services";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
  },
  methods: {
    goToDevelopment() {
      let nameDevFilt = services.normalize(this.item.developmentNameEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Development",
        params: {
          folio,
          nameDevFilt,
        },
      });
    },
  },
};
</script>
<style scoped>
.item {
  width: fit-content;
}

.watermark {
  height: 260px !important;
  width: 100%;
  background-size: 25%;
  z-index: 1;
  cursor: pointer;
}
.watermark {
  opacity: 0.6;
}

.single_property_price span {
  font-family: "Merriweather", sans-serif;
  color: #373737;
}

#propImg {
    width: 100%;
    height: 240px !important;
}

</style>
