<template>
  <div>
    <Preloader v-if="!dev" />
    <div v-else>
      <Banner
        :pageTitle="
          activeLocale == 'es' || !dev.developmentNameEn
            ? dev.developmentNameEs
            : dev.developmentNameEn
        "
      />
      <div class="blog_post ">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div class="bg-white property-body">
                <section class="row" v-if="dev.blueprints">
                  <div class="col-md-12 no-gutters text-center">
                    <div class="section-title text-center wow zoomIn">
                      <h2>
                        {{ $t("pages.development_detail.mapper_title") }}
                      </h2>
                      <div></div>
                    </div>
                    <div class="container mapper-container">
                      <ul
                        class="nav nav-pills mt-4 row justify-content-center"
                        id="mapperTab"
                        role="tablist"
                      >
                        <li
                          class="nav-item col-md-3"
                          v-for="(mapSection, i) in dev.blueprints"
                          :key="i"
                          :index="mapSection[i]"
                        >
                          <a
                            :class="[
                              'nav-link btn btn-primary section-button',
                              { 'active focusButton': show == `section${i}` },
                            ]"
                            :id="`section-tab-${i}`"
                            data-toggle="tab"
                            :href="`#section${i}`"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                            @click="forceRerender(`section${i}`)"
                            >{{
                              $t("pages.development_detail.mapper_section_name")
                            }}
                            {{ i + 1 }}</a
                          >
                        </li>
                      </ul>
                      <br />
                      <div class="row references mt-4">
                        <div class="col">
                          <div class="color-reference status-1"></div>
                          <span>{{
                            $t(
                              "pages.development_detail.mapper_status_available"
                            )
                          }}</span>
                        </div>
                        <div class="col">
                          <div class="color-reference status-2"></div>
                          <span>{{
                            $t(
                              "pages.development_detail.mapper_status_reserved"
                            )
                          }}</span>
                        </div>
                        <div class="col">
                          <div class="color-reference status-3"></div>
                          <span>{{
                            $t(
                              "pages.development_detail.mapper_status_unavailable"
                            )
                          }}</span>
                        </div>
                      </div>

                      <div class="tab-content mt-3 " id="mapperTabContent">
                        <div
                          v-for="(mapSection, i) in dev.blueprints"
                          :key="i"
                          :index="mapSection[i]"
                          :id="`section${i}`"
                          :class="[
                            'tab-pane fade',
                            { 'show active': show == `section${i}` },
                          ]"
                          role="tabpanel"
                          :aria-labelledby="`section-tab-${i}`"
                        >
                          <Mapper
                            v-if="renderComponent && show == `section${i}`"
                            :mapper="mapSection"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row references mt-4">
                      <div class="col">
                        <div class="color-reference status-1"></div>
                        <span>{{
                          $t("pages.development_detail.mapper_status_available")
                        }}</span>
                      </div>
                      <div class="col">
                        <div class="color-reference status-2"></div>
                        <span>{{
                          $t("pages.development_detail.mapper_status_reserved")
                        }}</span>
                      </div>
                      <div class="col">
                        <div class="color-reference status-3"></div>
                        <span>{{
                          $t(
                            "pages.development_detail.mapper_status_unavailable"
                          )
                        }}</span>
                      </div>
                    </div>
                    <div class="row justify-content-center mt-5 mb-5">
                      <div class="col-12">
                        <button
                          type="button"
                          @click="goToDetail()"
                          class="py-2 px-4 rounded-0 section-button"
                        >
                          {{
                            $t("pages.development_detail.mapper_return_button")
                          }}
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import ContactForm from "@/components/layout/ContactForm.vue";
import Mapper from "@/components/developments/Mapper.vue";
import services from "@/store/_services";
export default {
  components: {
    Banner,
    Preloader,
    ContactForm,
    Mapper,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
      renderComponent: true,
      show: "section0",
    };
  },
  computed: {
    ...mapGetters({
      dev: "_getDevelopment",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
    this.folio = this.$route.params.folio;

    let data = {
      folio: this.folio,
    };
    this.$store.dispatch("getDevelopment", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;

    // this.forceRerender("section0");
  },
  methods: {
    goToDetail() {
      let nameDevFilt = services.normalize(this.dev.developmentNameEs);
      let folio = this.dev.folio;
      this.$router.push({
        name: "Development",
        params: {
          folio,
          nameDevFilt,
        },
      });
    },
    forceRerender(section) {
      this.show = section;
      // remove the my-component component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // add my-component component in DOM
        this.renderComponent = true;
      });
    },
  },
};
</script>

<style scoped>
.references {
  margin: 10px 0;
  font-size: 13px !important;
}
.references span {
  margin-left: 10px;
}
.color-reference {
  width: 3rem;
  height: 1.6rem;
  border-radius: 0.25rem;
  display: inline-block;
  vertical-align: middle;
}
.status-1 {
  background: #93e945;
  border: 1px solid #86d241;
}
.status-2 {
  background: #ffc848;
  border: 1px solid #f5c047;
}
.status-3 {
  background: #e94545;
  border: 1px solid #cc3d3e;
}

.overall-scalable {
  width: 350px;
  height: 150px;
  overflow: hidden;
  -webkit-transition: all 1s;
}
.scalable {
  color: #666;
  width: 350px;
  height: 150px;
  -webkit-transform-origin: top left;
  -webkit-transition: all 1s;
}

.section-button {
  text-transform: uppercase;
  padding: 5px;
  color: #ef4b4a;
  cursor: pointer;
  border-radius: 0;
  white-space: pre-wrap;
  border: 1px solid #ef4b4a !important;
  font-size: 12px;
  text-transform: uppercase;
  transition: all 0.2s ease 0s;
  background-color: #fff;
}
.section-button:hover,
.section-button:focus,
.nav-pills .active,
.nav-pills .focusButton {
  background-color: #ef4b4a !important;
  color: #fff !important;
}

.fade{
    opacity: 1 !important;
}

.tab-pane{
  width: 100%;
  margin: auto;}

  .mapper-container{
    margin: auto !important;
  }
</style>
